<template>
  <v-container
    fluid
    tag="section"
  >
    <material-wizard
      title="Executar Sequência Elétrica"
      subtitle="Selecione a versão da BDGD e CTMTS"
      v-model="tab"
      :available-steps="tabsLiberadas"
      :tabs="tabs"
      class="mx-auto"
      @click:next="next()"
      @click:prev="back()"
      :loading="loading"
    >
      <sequenciamento-eletrico-wizard-tabs
        ref="sequenciamentoParametros"
        @step-observer="updateTabStatus($event)"
      />
    </material-wizard>
  </v-container>
</template>

<script>
import SequenciamentoEletricoService from '@/services/SequenciamentoEletricoService';
import wizardMixins from '@/mixins/wizardMixins';
import routes from '@/store/modules/routes';

export default {
  mixins: [wizardMixins],
  components: {
    SequenciamentoEletricoWizardTabs: () =>
      import(
        '@/components/perdas-tecnicas/sequenciamento-eletrico/SequenciamentoEletricoWizardTabs'
      ),
    MaterialWizard: () => import('@/components/base/MaterialWizard')
  },
  data: () => ({
    tabs: ['Mês dos Dados', 'Ctmts', 'Confirmação'],
    loading: false
  }),
  methods: {
    save() {
      const data = this.$refs.sequenciamentoParametros.exportData();

      this.loading = true;

      SequenciamentoEletricoService.save(data)
        .then(() => {
          this.$toast.success(
            'Execução de sequenciamento elétrico salva com sucesso.',
            '',
            {
              position: 'topRight'
            }
          );
          this.$router.replace({
            name: routes.state.appRoutes['SEQUENCIAMENTO_ELETRICO']
          });
        })
        .catch(() => {
          this.$toast.error(
            'Erro ao salvar a execução de sequenciamento elétrico.',
            '',
            {
              position: 'topRight'
            }
          );
        })
        .finally(() => (this.loading = false));
    }
  }
};
</script>
